<template>
  <MessageForm :auto-reply="autoReply" @submit="onSubmit" />
</template>

<script>
import get from "lodash/get";
import { hasSnackbarAccess } from "@/mixins/snackbar";
import MessageForm from "./components/MessageForm/MessageForm.vue";
import {
  STORE_MESSAGES_QUERY,
  STORE_MESSAGE_CREATE,
  STORE_MESSAGE_AUTO_RESPONSE_CREATE,
} from "./graphql";

export default {
  name: "NewMessageView",
  components: { MessageForm },
  mixins: [hasSnackbarAccess],
  props: {
    storeId: {
      type: [Number, String],
      required: true,
    },
    autoReply: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    async onSubmit(form) {
      const result = await this.$apollo.mutate({
        mutation: this.autoReply
          ? STORE_MESSAGE_AUTO_RESPONSE_CREATE
          : STORE_MESSAGE_CREATE,
        variables: {
          input: {
            ...form.values,
            storeId: this.storeId,
          },
        },
      });

      const { storeMessage, errors } = this.autoReply
        ? result.data.autoResponseCreate
        : result.data.createStoreMessage;

      if (errors.length > 0) {
        this.showSnackbar({
          text: "Error has happened while saving prewritten text message",
        });
      } else {
        this.showSnackbar({
          text: `Created ${storeMessage.title} message successfully`,
          color: "success",
        });

        this.addMessageToCache(
          this.$apollo.getClient().cache,
          result,
          this.autoReply
            ? "data.autoResponseCreate.storeMessage"
            : "data.createStoreMessage.storeMessage"
        );
        this.$router.back();
      }

      form.resolve();
    },
    /**
     * Callback for createMutation
     * @callback
     * @param {Object} cache - Apollo cache
     * @param {Object} mutationResult - Mutation result
     */
    addMessageToCache(cache, mutationResult, resultPath) {
      // Read the cache. Update the message in the cache or push it to the cache
      const newMessage = get(mutationResult, resultPath);

      const { messages = [] } = cache.readQuery({
        query: STORE_MESSAGES_QUERY,
        variables: { storeId: this.storeId },
      });
      messages.push(newMessage);
      cache.writeQuery({
        query: STORE_MESSAGES_QUERY,
        variables: { storeId: this.storeId },
        data: { messages },
      });
    },
  },
};
</script>
